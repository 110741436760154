import { ArcElement, Chart as ChartJS, Legend, RadialLinearScale, Tooltip } from 'chart.js';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import styles from './ChartStyles.module.css';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const PolarAreaChart = ({ labels, data }) => {
  const datatoSet = {
    datasets: data
  };

  return (
    <div className={styles.chartContainer}>
      <Doughnut 
        data={datatoSet}
      />
    </div>
  );
};

export default PolarAreaChart;
