import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 5
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right'
    }
  }
};

const HorizontalBarChart = ({ labels, data }) => {
  const datatoSet = {
    labels,
    datasets: data
  };

  return <Bar options={options} data={datatoSet} />;
};

export default HorizontalBarChart;
