import { CButton, CCol, CContainer, CForm, CFormInput, CFormLabel, CFormSelect, CFormSwitch, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import {CustomBreadcrumbs} from '../../components';
import { AppContext } from '../../context/AppContext';
import styles from './Suscription.module.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';



const Suscription = () => {

    let {suscriptionId} = useParams();

    //Context
    const { addSubscription, editSubscription, deleteSubscription, subscriptionApiBody, setSubscriptionApiBody, getSubscription, dispatcher, state } = React.useContext(AppContext);

    const navigate = useNavigate();

    const handleInputChange = (e) =>{
        const {id, value} = e.target;
        setSubscriptionApiBody({...subscriptionApiBody, [id]:value});
    }

    useEffect(() => {
        setSubscriptionApiBody({
            branches_limit:state?.subscription?.branches_limit || "",
            description:state?.subscription?.description || "",
            name:state?.subscription?.name || "",
            payment_frequency: state?.subscription?.payment_frequency || "",
            pg_subscription_plan_id: state?.subscription?.pg_subscription_plan_id || "",
            price: state?.subscription?.price || "",
            is_featured:state?.subscription?.is_featured || false,
        })
    },[state?.subscription])

    useEffect(() => {
        getSubscription(suscriptionId);
        return () => {
            dispatcher({ type: 'set', subscription: {} });
        };
    },[suscriptionId])



    return (
        <>
            <CustomBreadcrumbs parentTitle={'Veterinarias & lugares Pet Friendly'} activeTitle={suscriptionId ? "Editar suscripción" : "Agregar suscripción"} />
            <CContainer>
                <CRow>
                    <CCol xs={12} lg={6}>
                        <div className={styles.orderContainer}>
                            {/* Order header */}
                            <div className={styles.orderHeader}>
                                <h2 className='title'> 
                                    {suscriptionId ? "Editar suscripción" : "Agregar Suscripción"}
                                </h2>
                            </div>
                        </div>
                        <CForm>
                            <div className='mb-3'>
                                <CFormInput
                                    value={subscriptionApiBody?.name}
                                    type="text"
                                    id="name"
                                    label="Plan"
                                    placeholder="Plan"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                            <div className='mb-3'>
                                <CFormInput
                                    value={subscriptionApiBody?.description}
                                    type="text"
                                    id="description"
                                    label="Descripción"
                                    placeholder="Descripción"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                            <div className='mb-4'>
                                <CFormInput
                                    value={subscriptionApiBody?.price}
                                    type="number"
                                    id="price"
                                    label="Precio"
                                    placeholder="Precio"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                            <div className='mb-4'>
                                <CFormSelect
                                    label="Frecuencia del pago"
                                    id="payment_frequency"
                                    onChange={(e) => handleInputChange(e)}
                                    aria-label="Default select example"
                                    options={[
                                        { label: 'Seleccionar...', value: null, disabled: true },
                                        { label: 'Semana', value: 'semana' },
                                        { label: 'Mes', value: 'mes' },
                                        { label: 'Año', value: 'año'}
                                    ]}
                                    value={subscriptionApiBody?.payment_frequency}
                                />
                            </div>
                            <div className='mb-4'>
                                <CFormInput
                                    value={subscriptionApiBody?.branches_limit}
                                    type="number"
                                    id="branches_limit"
                                    label="Límite de sucursales"
                                    placeholder="Límite de sucursales"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                            <div className='mb-4'>
                                <CFormLabel>Destacar plan</CFormLabel>
                                <CFormSwitch
                                    type="checkbox"
                                    checked={subscriptionApiBody?.is_featured == "1"}
                                    id="is_featured"
                                    onChange={(e) => setSubscriptionApiBody({
                                        ...subscriptionApiBody,
                                        is_featured:e.target.checked
                                    })}
                                />
                            </div>
                            <div className='mb-4'>
                                <CFormInput
                                    value={subscriptionApiBody?.pg_subscription_plan_id}
                                    type="text"
                                    id="pg_subscription_plan_id"
                                    label="Código de plan de Mercado Pago"
                                    placeholder="Código"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                            <div className="d-grid gap-2 d-md-block">
                                <CButton 
                                    className={styles.button}
                                    onClick={() => suscriptionId ? 
                                        editSubscription(suscriptionId, subscriptionApiBody, setSubscriptionApiBody, navigate) : 
                                        addSubscription(subscriptionApiBody, setSubscriptionApiBody, navigate)}
                                >
                                    {suscriptionId ? "Guardar cambios" : "Agregar suscripción"}
                                </CButton>
                                {suscriptionId &&
                                    <CButton 
                                        onClick={() => deleteSubscription(suscriptionId, setSubscriptionApiBody, navigate)} 
                                        className={styles.buttonDelete}
                                    >
                                        Eliminar suscripción
                                    </CButton>
                                }
                                {!suscriptionId &&
                                    <CButton 
                                        onClick={() => navigate(-1)} 
                                        className={styles.buttonCancel}
                                    >
                                        Cancelar
                                    </CButton>
                                }
                            </div>
                        </CForm>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    );
};

export default Suscription;
