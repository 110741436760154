import { CContainer, CRow, CCol } from '@coreui/react';
import React, {useEffect} from 'react';
import { CardMetrics, CustomBreadcrumbs, UserTable } from '../../components';
import QRTable from '../../components/Table/QRTable';
import { AppContext } from '../../context/AppContext';
import received from '../../assets/images/cil-data-transfer-down.svg';
import process from '../../assets/images/cil-industry.svg';
import shipped from '../../assets/images/cil-truck.svg';


const QROrders = () => {

  const { state, getOrders, ordersPageUrl} = React.useContext(AppContext);
  const columns = [
    {
      key: '',
      label: 'Nº Pedido',
      _props: { scope: 'col' }
    },
    {
      key: 'date',
      label: 'Fecha',
      _props: { scope: 'col' }
    },
    {
      key: 'status',
      label: 'Estado',
      _props: { scope: 'col' }
    },
    {
      key: 'fullname',
      label: 'Nombre y apellido',
      _props: { scope: 'col' }
    },
    {
      key: 'shipping_code',
      label: 'Código de seguimiento',
      _props: { scope: 'col' }
    },
    {
      key: 'actions',
      label: 'Acciones',
      _props: { scope: 'col' }
    }
  ];
  const orders = state?.orders?.map((order) => {
    const date = new Date(order.created_at);
    const formattedDate = `${date.getDate()}/${(date.getMonth()+1)}/${date.getFullYear()}`;
    return {
      id: order.id,
      date: formattedDate,
      order_status: order.status_shipping,
      fullname: order.full_name,
      shipping_code: order.tracking_code,
    };
  });

  //Effects
  useEffect(() => {
    getOrders();
  }, [ordersPageUrl]);


  return (
    <div>
      <CustomBreadcrumbs parentTitle={'Monetización'} activeTitle="Identificadores QR" />
      <CContainer>
        <CRow className="justify-content-between">
          <CCol xs={12}>
            <h2 className='title'>Resumen</h2>
          </CCol>
          <CCol xs={12} lg={4}>
            <CardMetrics 
              title={state?.orders?.filter((u) => u.status_shipping.id === 2).length} 
              subtitle="Pedidos pendientes" 
              cardStyle="primary" 
              icon={received}
            />
          </CCol>
          <CCol xs={12} lg={4}>
            <CardMetrics 
              title={state?.orders?.filter((u) => u.status_shipping.id === 3).length} 
              subtitle="Pedidos en producción" 
              cardStyle="secondary" 
              icon={process}
            />
          </CCol>
          <CCol xs={12} lg={4}>
            <CardMetrics 
              title={state?.orders?.filter((u) => u.status_shipping.id === 4).length} 
              subtitle="Pedidos enviados" 
              cardStyle="tertiary" 
              icon={shipped}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol xs={12}>
            <h2 className='title mb-4'>Pedidos</h2>
          </CCol>
          <QRTable 
            columns={columns} 
            data={orders} 
            pagination={state?.ordersPagination} 
          />
        </CRow>
      </CContainer>
    </div>
  );
};

export default QROrders;
