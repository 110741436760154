import { CCol, CRow } from '@coreui/react';
import React from 'react';
import styles from './CardChart.module.css';

const Card = ({ title, chart, references }) => {
  return (
    <CRow>
      <CCol xs={4} style={{paddingTop:40,}}>
        {
          references?.map((reference,i) => (
            <div key={i} className={styles.referenceItem}>
              <div className={styles.referenceDot} style={{backgroundColor:reference.color}}></div>
              <p className={styles.referenceLabel}>{reference.label}</p>
            </div>
          ))
        }
      </CCol>
      <CCol xs={8} className={[styles['general'], 'justify-content-center'].join(' ')}>
        <h5>{title}</h5>
        {chart}
      </CCol>
    </CRow>
  );
};

export default Card;
