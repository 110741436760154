import { toast } from 'react-toastify';
export const ErrorHandler = (error) => {
  if (error?.response?.data?.errors) {
    let testError = error?.response?.data?.errors;
    let errorString = '';
    Object.values(testError).map((error) => (errorString = errorString + error.join() + '\n'));
    toast(errorString);
  } else if (error?.response?.data && !error?.response?.data?.errors) {
    toast(error?.response?.data?.message);
  } else {
    toast('Hubo un problema al procesar tu pedido. Vuelve a intentarlo nuevamente');
  }
};

export const onHideToast = (onHide) => {
  toast.onChange((payload) => {
    if (payload.status === 'removed') {
      onHide();
    }
  });
};
