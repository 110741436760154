
import React from 'react';
import styles from './CardBranch.module.css';
import paw from '../../assets/images/cil-paw.svg';
import phoneIcon from '../../assets/images/cil-phone.svg';
import envelope from '../../assets/images/cil-envelope-closed.svg';
import wpp from '../../assets/images/cib-whatsapp.svg';
import locationPin from '../../assets/images/cil-location-pin.svg';

const Card = ({ data }) => {
  const {name, location, phone, whatsapp, email} = data;
  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <img src={paw} alt="icon" />
      </div>
      <h3 className={styles.title}>{name}</h3>
      <div className={styles.infoContainer}>
        <p><img src={locationPin} alt="icon"/>{location.address}</p>
      </div>
      <div className={styles.infoContainer}>
        <p><img src={envelope} alt="icon"/>{email}</p>
      </div>
      <div className={styles.infoContainer}>
        <p><img src={wpp} alt="icon"/>{whatsapp}</p>
      </div>
      <div className={styles.infoContainer}>
        <p><img src={phoneIcon} alt="icon"/>{phone}</p>
      </div>
    </div>
  );
};

export default Card;
