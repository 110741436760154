import { CButton, CCol, CContainer, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import {CustomBreadcrumbs} from '../../components';
import { AppContext } from '../../context/AppContext';
import styles from './Suscriptions.module.css';
import ProductsTable from '../../components/Table/ProductsTable';
import { useNavigate } from 'react-router-dom';
import SubscriptionsTable from '../../components/Table/SubscriptionsTable';



const Suscriptions = () => {

    //Context
    const { state, getSubscriptions, } = React.useContext(AppContext);

    //States
    const columns = [
        {
          key: '',
          label: '#',
          _props: { scope: 'col' }
        },
        {
          key: 'title',
          label: 'Plan',
          _props: { scope: 'col' }
        },
        {
          key: 'description',
          label: 'Descripción',
          _props: { scope: 'col' }
        },
        {
          key: 'price',
          label: 'Precio',
          _props: { scope: 'col' }
        },
        {
          key: 'actions',
          label: 'Acciones',
          _props: { scope: 'col' }
        }
    ];

    const navigate = useNavigate();

    //Effect
    useEffect(() => {
        getSubscriptions();
    },[])


    return (
        <>
            <CustomBreadcrumbs parentTitle={'Veterinarias & lugares Pet Friendly'} activeTitle={"Suscripciones"} />
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol lg={12}>
                        <div className={styles.orderContainer}>
                            {/* Order header */}
                            <div className={styles.orderHeader}>
                                <h2 className='title'>Suscripciones</h2>
                                <CButton 
                                    size="lg" 
                                    color="primary" 
                                    onClick={() => navigate("/dashboard/subscriptions/add")} 
                                    className={styles.button}
                                >
                                    Agregar suscripción
                                </CButton>
                            </div>
                        </div>
                        <div className={styles.productsContainer}>
                            <SubscriptionsTable
                                columns={columns} 
                                data={state?.subscriptions}
                                pagination={state?.productsPagination} 
                            />
                        </div>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    );
};

export default Suscriptions;
