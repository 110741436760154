import {
    CButton,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow
  } from '@coreui/react';
  import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
  import styles from './Table.module.css';
import TableFooter from './TableFooter';
  
  
  
  const ProductsTable = ({ columns, data, pagination }) => {

    const navigate = useNavigate();
  
    const {setProductsPageUrl} = useContext(AppContext);
  
    return (
        <>
          <CTable responsive hover>
            <CTableHead>
              <CTableRow>
                {columns.map((col, index) => (
                  <CTableHeaderCell key={index} scope="col">
                    {col.label}
                  </CTableHeaderCell>
                ))}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {data?.map((el, index) => (
                <CTableRow key={index}>
                  <CTableDataCell>{el.id}</CTableDataCell>
                  <CTableDataCell>{el.name}</CTableDataCell>
                  <CTableDataCell>${el.price}</CTableDataCell>
                  <CTableDataCell>
                    <CButton
                      className={styles.downloadQR}
                      onClick={() => {
                        navigate(`/dashboard/settings/products/${el?.id}?name=${el.name}&price=${el.price}`);
                      }}
                    >
                      Editar
                    </CButton>
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
          <TableFooter range={pagination?.links} setPageUrl={setProductsPageUrl} />
      </>
    );
  };
  
  export default ProductsTable;
  