import { CButton, CCol, CContainer, CModal, CModalBody, CModalHeader, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import {CustomBreadcrumbs} from '../../components';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import styles from './Company.module.css';
import back from '../../assets/images/cil-arrow-left.svg';
import { useNavigate } from 'react-router-dom';
import industry from '../../assets/images/cil-industry.svg';
import info from '../../assets/images/cil-description.svg';
import building from '../../assets/images/cil-building.svg';
import card from '../../assets/images/cil-credit-card.svg';
import CardBranch from '../../components/CardBranch/CardBranch';



const Company = () => {

    const { state, getCompany, getBranchesByCompanyId, dispatcher, disableCompany, enableCompany } = React.useContext(AppContext);

    //Navigation
    let { companyId } = useParams();
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    
    //Effect
    useEffect(() => {
        getCompany(companyId);
        getBranchesByCompanyId(companyId);
        return () => {
            dispatcher({ type: 'set', company: {} });
        };
    },[companyId])

    return (
        <div>
            <CustomBreadcrumbs parentTitle={'Veterinarias & lugares Pet Friendly'} activeTitle={`Empresa ${state?.company?.company_name}`} />
            <CContainer>
                <CRow className="justify-content-between">
                    <CCol lg={12}>
                        <CRow className="justify-content-center">
                            <CCol lg={8}>
                                <div 
                                    className={styles.backButtonContainer}
                                    onClick={() => navigate(-1)}
                                >    
                                    <img src={back} width="20" alt="back" />
                                    <p className={styles.backButton}>Volver</p>
                                </div>
                            </CCol>
                            <CCol lg={8}>
                                <div className={styles.orderContainer}>
                                    {/* Order header */}
                                    <div className={styles.orderHeader}>
                                        <h2 className='title'>Empresa {state?.company?.company_name}</h2>
                                    </div>

                                    {/* Order Body */}
                                    <div className={styles.orderBodySection}>
                                        <div className={[styles['orderSectionIcon'], styles['primary']].join(' ')}>
                                            <img src={industry} alt="icon" />
                                        </div>
                                        <div className={styles.orderSectionContent}>
                                            <h3 className={styles.orderSubtitle}>Datos empresariales</h3>
                                            <p><b>Nombre de la empresa: </b>{state?.company?.company_name}</p>
                                            <p><b>Nombre del dueño: </b>{state?.company?.owner_name}</p>
                                            <p><b>Categoría: </b>{state?.company?.company_category?.name}</p>
                                            <p><b>Email: </b>{state?.company?.email}</p>
                                            <p><b>Teléfono: </b>{state?.company?.phone || "-"}</p>
                                            <p><b>Estado: </b>{state?.company?.disabled ? "Deshabilitado" : "Habilitado"}</p>
                                            <CButton
                                                className={state?.company?.disabled ? styles.habilitar : styles.deshabilitar}
                                                onClick={() => setShowModal(true)}
                                            >
                                                {state?.company?.disabled ? "Habilitar empresa" : "Deshabilitar empresa"}
                                            </CButton>
                                        </div>
                                    </div>
                                    <div className={styles.orderBodySection}>
                                        <div className={[styles['orderSectionIcon'], styles['secondary']].join(' ')}>
                                            <img src={info} alt="icon" />
                                        </div>
                                        <div className={styles.orderSectionContent}>
                                            <h3 className={styles.orderSubtitle}>Datos de facturación</h3>
                                            <p><b>Nombre y apellido: </b>{state?.company?.billing_name}</p>
                                            <p><b>CUIT/CUIL: </b>{state?.company?.billing_cuit}</p>
                                            <p><b>Condición frente al IVA: </b>{state?.company?.company_tax_status?.name}</p>
                                            <p><b>Teléfono: </b>{state?.company?.billing_phone}</p>
                                            <p><b>Email: </b>{state?.company?.billing_email}</p>
                                        </div>
                                    </div>
                                    <div className={styles.orderBodySection}>
                                        <div className={[styles['orderSectionIcon'], styles['success']].join(' ')}>
                                            <img src={card} alt="icon" />
                                        </div>
                                        
                                        <div className={styles.orderSectionContent}>
                                            <h3 className={styles.orderSubtitle}>Datos de suscripción</h3>
                                            <p><b>Plan: </b>{state?.company?.subscription?.subscription_plan.name}</p>
                                            <p><b>Precio: </b>${state?.company?.subscription?.subscription_plan.price}/mes</p>
                                            <p><b>Estado: </b>{state?.company?.subscription?.status.name}</p>
                                        </div>
                                    </div>
                                    <div className={styles.orderBodySection}>
                                        <div className={[styles['orderSectionIcon'], styles['tertiary']].join(' ')}>
                                            <img src={building} alt="icon" />
                                        </div>
                                        <div className={styles.orderSectionContent}>
                                            <h3 className={styles.orderSubtitle}>Sucursales</h3>
                                            <CRow>
                                                {state.company?.branches ? 
                                                    state?.company?.branches?.map((branch,i) =>
                                                        <CCol xs={12} xl={4}>
                                                            <CardBranch
                                                                data={branch}
                                                                key={i}
                                                            />
                                                        </CCol>
                                                    ) :
                                                    <CCol xs={12}>
                                                        <p>La compañía todavía no creó ninguna sucursal.</p>
                                                    </CCol>
                                                }
                                            </CRow>
                                        </div>
                                    </div>
                                    {/* <br />
                                    <div
                                        style={{textAlign:"right"}}
                                    >
                                        <a 
                                            className={submitSuccess ? 'successBtn' :'generalBtn'}
                                            onClick={() => editOrder(companyId)}
                                            disabled={loading && submitSuccess}
                                        >
                                            {
                                                loading ? 
                                                <img src={spinner} width="15" alt="loader"/> :
                                                submitSuccess ?
                                                "¡Cambios guardados con éxito!" :
                                                "Guardar cambios"
                                            }
                                        </a>
                                    </div> */}
                                </div>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
            </CContainer>
            <CModal visible={showModal} onClose={() => setShowModal(false)}>
                <CModalHeader>
                    {state?.company?.disabled ? "Habilitar empresa" : "Deshabilitar empresa"}
                </CModalHeader>
                <CModalBody>
                    {state?.company?.disabled ?
                        <p>¿Querés habilitar esta empresa?<br/>Si habilitás esta compañía, el usuario volverá a poder iniciar sesión en el dashboard de veterinarias.<br/>Luego podrás volver a deshabilitarla.</p> :
                        <p>¿Querés deshabilitar esta empresa?<br/>Si deshabilitás esta compañía, el usuario no podrá iniciar sesión en el dashboard de veterinarias.<br/>Luego podrás volver a habilitarla.</p>
                    }
                    <CButton
                        className={state?.company?.disabled ? styles.habilitar : styles.deshabilitar}
                        onClick={state?.company?.disabled ? 
                            () => {enableCompany(companyId); setShowModal(false)} : 
                            () => {disableCompany(companyId, navigate); setShowModal(false)}
                        }
                    >
                        {state?.company?.disabled ? "Habilitar empresa" : "Deshabilitar empresa"}
                    </CButton>
                </CModalBody>
            </CModal>
        </div>
    );
};

export default Company;
