import { CForm, CFormInput, CButton, CFormLabel } from '@coreui/react';
import React from 'react';
import styles from './FormLogin.module.css';
import { AuthContext } from '../../context/AuthContext';



const FormLogin = () => {

  const { signIn } = React.useContext(AuthContext);
  const [isValid, setIsValid] = React.useState();
  const [form, setForm] = React.useState({
    email: null,
    password: null
  });

  const onChangeInput = (evt) => {
    const { name, value } = evt.target;
    setForm({ ...form, [name]: value });
  };
  const handleSubmit = (evt) => {
    evt.preventDefault();
    signIn(form);
  };

  React.useEffect(() => {
    form.email !== null && form.password !== null ? setIsValid(true) : setIsValid(false);
  }, [form]);


  
  return (
    <CForm className="text-left justify-content-start" onSubmit={handleSubmit}>
      <CFormLabel className={styles['customLabel-left']}>Email</CFormLabel>
      <CFormInput type="email" id="email" name="email" className="mb-3" onChange={onChangeInput} />
      <CFormLabel className={styles['customLabel-left']}>Contraseña</CFormLabel>
      <CFormInput
        type="password"
        id="password"
        name="password"
        className="mb-3"
        onChange={onChangeInput}
      />
      <CButton type="submit" className="generalBtn" disabled={!isValid}>
        Iniciar sesion
      </CButton>
    </CForm>
  );
};

export default FormLogin;
