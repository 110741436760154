import React from 'react';
import axios from 'axios';
import { ErrorHandler } from '../utils/ErrorHandler';
import { API_URL } from '../constants';
const AuthContext = React.createContext();
const { Provider } = AuthContext;



const AuthProvider = ({ children }) => {

  const [userInfo, setUserInfo] = React.useState();
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');

  // State dispatch for login
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: token ? token : null
    }
  );

  // Iniciar sesion
  const signIn = async (data) => {
    axios
      .post(`${API_URL}login`, {
        email: data.email,
        password: data.password
      })
      .then((res) => {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('user', JSON.stringify(res.data.user));
        dispatch({ type: 'SIGN_IN', token: res.data.token });
      })
      .catch((err) => {
        ErrorHandler(err);
      });
  };

  // Logout
  const signOut = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      axios
        .post(`${API_URL}logout`)
        .then((res) => {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          dispatch({ type: 'SIGN_OUT' });
        })
        .catch(async (err) => {
          console.error(err);
        });
    }
  };

  React.useEffect(() => {
    setUserInfo(user);
  }, [token, user]);

  
  return (
    <Provider
      value={{
        state,
        dispatch,
        signIn,
        signOut,
        userInfo,
        setUserInfo
      }}>
      {children}
    </Provider>
  );
};
export { AuthContext, AuthProvider };
