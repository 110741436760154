import {
  CCard,
  CCardBody,
  CCol,
  CRow
} from '@coreui/react';
import styles from './CardMetrics.module.css';



const Card = ({ title, subtitle, cardStyle = 'general', hasChart = false, icon }) => {
  return (
      <CCard className={styles.general}>
        <CCardBody>
          <CRow>
            <CCol xs={4}>
              <div className={styles[cardStyle]}>
                <img src={icon} className={styles[`${cardStyle}-img`]} alt="icon" />
              </div>
            </CCol>
            <CCol xs={8} className="d-flex flex-column justify-content-center">
              <p className={styles.subtitle}>{subtitle}</p>
              <h2 className={styles.title}>{title}</h2>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
  );
};

export default Card;
