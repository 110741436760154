import {
  CButton,
  CCol,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import React, {useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Table.module.css';
import { AppContext } from '../../context/AppContext';
import TableFooter from './TableFooter';




const Table = ({ columns, data, pagination }) => {

  const {setCompaniesPageUrl} = useContext(AppContext);

  //Navigation
  const navigate = useNavigate();

  //Methods
  const handleAction = (id) => {
    
  }

  return (
    <>
      {/* <CCol className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por Nº de pedido"
          style={{ width: '100%' }}
        />
      </CCol>
      <CCol className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por nombre o apellido"
          style={{ width: '100%' }}
        />
      </CCol> */}

        <CTable responsive hover>
          <CTableHead>
            <CTableRow>
              {columns.map((col, index) => (
                <CTableHeaderCell key={index} scope="col">
                  {col.label}
                </CTableHeaderCell>
              ))}
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {data?.map((company, index) => (
              <CTableRow key={index}>
                <CTableDataCell>{company.id}</CTableDataCell>
                <CTableDataCell>{company.company_name}</CTableDataCell>
                <CTableDataCell>
                  {company.company_category.name}
                </CTableDataCell>
                <CTableDataCell>{company.owner_name}</CTableDataCell>
                <CTableDataCell>{company.email}</CTableDataCell>
                <CTableDataCell>{company.phone || "-"}</CTableDataCell>
                <CTableDataCell style={{textAlign:"center"}}>{company.branches.length}</CTableDataCell>
                <CTableDataCell>
                  <CButton 
                    className={`${styles['downloadQR']}`} 
                    style={{width:"max-content"}}
                    onClick={() => navigate(`/dashboard/companies/${company?.id}`)}
                  >
                    Ver empresa
                  </CButton>
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
        <TableFooter range={pagination?.links} setPageUrl={setCompaniesPageUrl} />
    </>
  );
};

export default Table;
