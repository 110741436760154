import React from 'react';
import { CBreadcrumb, CBreadcrumbItem, CCol, CContainer, CLink, CRow, CSidebarToggler } from '@coreui/react';
import { AppContext } from '../../context/AppContext';
import styles from './Breadcrumbs.module.css';
import CIcon from '@coreui/icons-react';
import { cilMenu } from '@coreui/icons';
const Breadcrumbs = ({ parentTitle, parentUrl, activeTitle }) => {
  const { dispatcher, state } = React.useContext(AppContext);
  return (
    <CRow className={styles['container']}>
      <CCol>
        <CContainer>
          <CRow>
            <CCol xs={1} className="d-flex d-lg-none p-0 align-items-center">
              <CSidebarToggler
                onClick={() => dispatcher({ type: 'set', sidebarShow: !state.sidebarShow })}
                className="d-flex justify-content-start w-100"
              >
                <CIcon icon={cilMenu} size="xl" />
              </CSidebarToggler>
            </CCol>
            <CCol xs={10} lg={12}>
              <CBreadcrumb style={{ padding: 0, margin: 0 }}>
                <CBreadcrumbItem>
                  <CLink href={parentUrl} className={styles['parentTitle']}>
                    {parentTitle}
                  </CLink>
                </CBreadcrumbItem>
                <CBreadcrumbItem active>{activeTitle}</CBreadcrumbItem>
              </CBreadcrumb>
            </CCol>
          </CRow>
        </CContainer>
      </CCol>
    </CRow>
  );
};

export default Breadcrumbs;
