import { CContainer, CRow, CCol } from '@coreui/react';
import React, {useEffect} from 'react';
import { CustomBreadcrumbs,  } from '../../components';
import CompaniesTable from '../../components/Table/CompaniesTable';
import { AppContext } from '../../context/AppContext';

const Companies = () => {

  const { state, getCompanies, companiesPageUrl} = React.useContext(AppContext);
  const columns = [
    {
      key: '',
      label: '#',
      _props: { scope: 'col' }
    },
    {
      key: 'name',
      label: 'Empresa',
      _props: { scope: 'col' }
    },
    {
      key: 'category',
      label: 'Categoría',
      _props: { scope: 'col' }
    },
    {
      key: 'fullname',
      label: 'Responsable',
      _props: { scope: 'col' }
    },
    {
      key: 'email',
      label: 'Email',
      _props: { scope: 'col' }
    },
    {
      key: 'phone',
      label: 'Teléfono',
      _props: { scope: 'col' }
    },
    {
      key: 'branches',
      label: 'Sucursales',
      _props: { scope: 'col' }
    },
    {
      key: 'actions',
      label: 'Acciones',
      _props: { scope: 'col' }
    }
  ];

  //Effects
  useEffect(() => {
    getCompanies();
  }, [companiesPageUrl]);

  return (
    <div>
      <CustomBreadcrumbs parentTitle={'Veterinarias & lugares Pet Friendly'} activeTitle="Empresas" />
      <CContainer>
        <CRow>
          <CCol xs={12}>
            <h2 className='title mb-4'>Empresas</h2>
          </CCol>
          <CompaniesTable
            columns={columns} 
            data={state?.companies} 
            pagination={state?.companiesPagination} 
          />
        </CRow>
      </CContainer>
    </div>
  );
};

export default Companies;
