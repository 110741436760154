import { CCol, CContainer, CRow } from '@coreui/react';
import React, {useContext, useEffect} from 'react';
import { CardChart, CardMetrics, CustomBreadcrumbs, PolarAreaChart } from '../../components';
import { AppContext } from '../../context/AppContext';
import user from '../../assets/images/cil-user.svg';
import dog from '../../assets/images/cil-dog.svg';
import qr from '../../assets/images/cil-qr-code.svg';
import received from '../../assets/images/cil-data-transfer-down.svg';
import process from '../../assets/images/cil-industry.svg';
import shipped from '../../assets/images/cil-truck.svg';
import styles from './Home.module.css'



const Home = () => {

  const { state, getDashboardData } = useContext(AppContext);

  const PETS_CHART_DATA = [
    {
      label: 'Perros',
      data: [
        state?.stats?.alerts?.at_home,
        state?.stats?.alerts?.lost,
        state?.stats?.alerts?.found
      ],
      backgroundColor: [
        '#36BD94',
        '#E04360',
        '#651DFF',
      ],
      borderWidth: 1,
    }
  ];

  //Effects
  useEffect(() => {
    getDashboardData();
  }, []);


  return (
    <div>
      <CustomBreadcrumbs parentTitle={'Home'} activeTitle="Dashboard" />
      <CContainer>
        <CRow className="justify-content-between">
          <CCol lg={12}>
            <CRow>
              <CCol xs={12}>
                <h2 className='title'>Resumen</h2>
              </CCol>
              <CCol xs={12} lg={4}>
                <CardMetrics 
                  title={state?.stats?.users} 
                  subtitle="Usuarios registrados" 
                  cardStyle="primary" 
                  icon={user}
                />
              </CCol>
              <CCol xs={12} lg={4}>
                <CardMetrics
                  title={state?.stats?.pets?.total}
                  subtitle="Mascotas creadas"
                  cardStyle="secondary"
                  icon={dog}
                />
              </CCol>
              <CCol xs={12} lg={4}>
                <CardMetrics 
                  title={state?.stats?.badge_requests} 
                  subtitle="Identificadores QR" 
                  cardStyle="tertiary" 
                  icon={qr}
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow className="justify-content-between">
          <CCol lg={6}>
            <CRow>
              <CCol xs={12}>
                <h2 className='title'>Alertas</h2>
              </CCol>
              <CCol className={styles.chartContainer}>
                <CardChart
                  references={[{color:'#36BD94', label:"En casa"},{color:'#E04360',label:"Perdidos"},{color:'#651DFF', label:"Encontrados"}]}
                  chart={
                    <PolarAreaChart
                      labels={['En casa', 'Perdidos', 'Encontrados']}
                      data={PETS_CHART_DATA}
                    />
                  }
                />
              </CCol>
            </CRow>
          </CCol>
          {/* <CCol lg={6}>
            <CRow>
              <CCol xs={12}>
                <h2 className='title'>Pedidos</h2>
              </CCol>
              <CCol className={styles.chartContainer}>
                <CRow>
                  <CCol lg={4} className={styles.orderContainer}>
                    <div className={[styles.iconContainer, styles['primary']].join(' ')}>
                      <img src={received} className={styles['primary-img']} alt="icon" />
                    </div>
                    <h2>23</h2>
                    <p>Pedidos recibidos</p>
                  </CCol>
                  <CCol lg={4} className={styles.orderContainer}>
                    <div className={[styles.iconContainer, styles['secondary']].join(' ')}>
                      <img src={process} className={styles['secondary-img']} alt="icon" />
                    </div>
                    <h2>23</h2>
                    <p>Pedidos en proceso</p>
                  </CCol>
                  <CCol lg={4} className={styles.orderContainer}>
                    <div className={[styles.iconContainer, styles['tertiary']].join(' ')}>
                      <img src={shipped} className={styles['tertiary-img']} alt="icon" />
                    </div>
                    <h2>23</h2>
                    <p>Pedidos enviados</p>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCol> */}
        </CRow>
      </CContainer>
    </div>
  );
};

export default Home;
