import { CButton, CCol, CContainer, CForm, CFormInput, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import {CustomBreadcrumbs} from '../../components';
import { AppContext } from '../../context/AppContext';
import styles from './SettingsProduct.module.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';



const SettingsProduct = () => {


    const useQuery = () => {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();

    let {productId} = useParams();

    const [apiBody, setApiBody] = useState({name:query?.get("name"), price:query?.get("price")});

    //Context
    const { addProduct, editProduct, deleteProduct } = React.useContext(AppContext);

    const navigate = useNavigate();

    const handleInputChange = (e) =>{
        const {id, value} = e.target;
        setApiBody({...apiBody, [id]:value});
    }


    return (
        <>
            <CustomBreadcrumbs parentTitle={'Configuración'} activeTitle={productId ? "Editar producto" : "Agregar Producto"} />
            <CContainer>
                <CRow>
                    <CCol xs={12} lg={6}>
                        <div className={styles.orderContainer}>
                            {/* Order header */}
                            <div className={styles.orderHeader}>
                                <h2 className='title'> 
                                    {productId ? "Editar producto" : "Agregar Producto"}
                                </h2>
                            </div>
                        </div>
                        <CForm>
                            <div className='mb-3'>
                                <CFormInput
                                    value={apiBody?.name}
                                    type="text"
                                    id="name"
                                    label="Título"
                                    placeholder="Título"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                            <div className='mb-4'>
                                <CFormInput
                                    value={apiBody?.price}
                                    type="number"
                                    id="price"
                                    label="Precio"
                                    placeholder="Precio"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                            <div className="d-grid gap-2 d-md-block">
                                <CButton 
                                    className={styles.button}
                                    onClick={() => productId ? editProduct(productId, apiBody, setApiBody, navigate) : addProduct(apiBody, setApiBody, navigate)}
                                >
                                    {productId ? "Guardar cambios" : "Agregar Producto"}
                                </CButton>
                                {productId &&
                                    <CButton 
                                        onClick={() => deleteProduct(productId, setApiBody, navigate)} 
                                        className={styles.buttonDelete}
                                    >
                                        Eliminar producto
                                    </CButton>
                                }
                                {!productId &&
                                    <CButton 
                                        onClick={() => navigate(-1)} 
                                        className={styles.buttonCancel}
                                    >
                                        Cancelar
                                    </CButton>
                                }
                            </div>
                        </CForm>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    );
};

export default SettingsProduct;
