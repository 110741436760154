import { CContainer, CRow, CCol } from '@coreui/react';
import { CustomFormLogin } from '../../components';
import logo from '../../assets/images/logo.png';
import styles from './Login.module.css';


const Login = () => {
  return (
    <CContainer fluid className={styles['loginContainer']}>
      <CRow className={styles['loginRow']}>
        <CCol className="text-center justify-content-center">
          <img src={logo} className={styles['loginLogoImage']} />
          <CustomFormLogin />
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default Login;
